//Base URl
export const BASE_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY;

//login
export const API_ENDPOINT_LOGIN = "users/admin/login";
export const API_ENDPOINT_LOGIN_REFRESH = "users/auth/refresh";

// dashboard
export const API_ENDPOINT_DASHBOARD =
  "/admin/dashboard?page=1&per_page=10&enquiry_status=pending&company_verified=2&resource_verified=2";
//users
export const API_ENDPOINT_USERS_LIST = "users/seller-listing";
export const API_ENDPOINT_BUYER_USERS_LIST = "users/buyer-listing";
export const API_ENDPOINT_SELLER_DISABLE = "admin/seller";
export const API_ENDPOINT_BUYER_DISABLE = "admin/buyer";
export const API_ENDPOINT_BUYER_DETAIL_BY_ID = "users/buyer/details/";
export const API_ENDPOINT_SELLER_DETAIL_BY_ID = "users/seller/details/";
//certification
export const API_ENDPOINT_CREATE_CERTIFICATE = "certification/create";
export const API_ENDPOINT_UPDATE_CERTIFICATE = "certification/update";
export const API_ENDPOINT_GET_CERTIFICATE = "certification/list";
export const API_ENDPOINT_GET_DETAILS_CERTIFICATE = "certification/get";

//technology
export const API_ENDPOINT_GET_TECHNOLOGY = "technology/list";
export const API_ENDPOINT_GET_DETAILS_TECHNOLOGY = "technology/get";
export const API_ENDPOINT_CREATE_TECHNOLOGY = "technology/create";
export const API_ENDPOINT_UPDATE_TECHNOLOGY = "technology/update";

//location
export const API_ENDPOINT_GET_LOCATION = "location/list";
export const API_ENDPOINT_GET_DETAILS_LOCATION = "location/get";
export const API_ENDPOINT_CREATE_LOCATION = "location/create";
export const API_ENDPOINT_UPDATE_LOCATION = "location/update/city/";
export const API_ENDPOINT_UPDATE_STATE = "location/update/state";
export const API_ENDPOINT_CREATE_STATE = "location/create/state";

//blog
export const API_ENDPOINT_GET_BLOG = "blog/list";
export const API_ENDPOINT_GET_DETAILS_BLOG = "blog/detail";
export const API_ENDPOINT_CREATE_BLOG = "blog/create";
export const API_ENDPOINT_UPDATE_BLOG = "blog/update";
export const API_ENDPOINT_DELETE_BLOG = "blog/remove";

//image
export const API_UPLOAD_IMAGE = "image/upload";
export const API_GET_IMAGE = "media/imagelist";
export const API_GET_IMAGE_RENAME = "media/name-update";
// FILE UPLOAD
export const POST_FILE_UPLOAD = "file/upload";
//comapnylist
export const API_ENDPOINT_GET_COMPANYLIST = "company/list";
export const API_ENDPOINT_GET_COMPANYLIST_DETAILS = `/company/details`;
export const API_ENDPOINT_POST_COMPANY_VERIFY = "company/verify";
export const API_ENDPOINT_POST_COMPANY_DISABLE = "company/disable";
export const API_ENDPOINT_POST_COMPANY_CREATE = "/admin/update-company";
export const API_ENDPOINT_GET_VERIFIED_COMPANYLIST = "company/list?verified=1";
export const API_ENDPOINT_GET_PENDING_COMPANYLIST = "company/list?verified=2";
export const API_ENDPOINT_GET_REJECTED_COMPANYLIST = "company/list?verified=0";
//resourcelist
export const API_ENDPOINT_GET_VERIFIED_RESOURCELIST = "resource/list";
export const API_ENDPOINT_GET_PENDING_RESOURCELIST = "resource/list";
export const API_ENDPOINT_GET_REJECTED_RESOURCELIST = "resource/list";
export const API_ENDPOINT_GET_RESOURCELIST = "resource/list";
export const API_ENDPOINT_UPDATE_RESOURCELIST = "admin/resource/update";
export const API_ENDPOINT_GET_RESOURCELIST_DETAILS = "resource/get";
export const API_ENDPOINT_POST_RESOURCE_VERIFY = "resource/verify";
export const API_ENDPOINT_POST_RESOURCE_DISABLE = "resource/disable";
export const API_ENDPOINT_POST_CREATE_RESOURCE = "admin/resource/create";
export const API_ENDPOINT_POST_ADD_MEMBER = "/send-organization-invite";
export const API_ENDPOINT_POST_REMOVE_MEMBER = "/users/remove-user/";
export const API_ENDPOINT_POST_UPDATE_MEMBER = "/users/update-role/";
export const API_ENDPOINT_POST_SKILL_EVALUATION = "admin/skill-evaluation/";
//bulk_upload
export const API_ENDPOINT_POST_BULK_UPLOAD_LOCATION = "bulk/location/upload";
export const API_ENDPOINT_POST_BULK_UPLOAD_CERTIFICATION =
  "bulk/certification/upload";
export const API_ENDPOINT_POST_BULK_UPLOAD_INQUIRY = "/bulk/enquiry/upload";

//inquiry
export const API_ENDPOINT_GET_INQUIRY_ADMIN_LIST = "admin/enquiry/list";
export const API_ENDPOINT_GET_INQUIRY_TOTAL_LIST = "enquiry/total/list";
export const API_ENDPOINT_POST_INQUIRY_REMARK = "admin/enquiry/create-remark";
export const API_ENDPOINT_POST_INQUIRY_CREATE = "enquiry/create";
export const API_ENDPOINT_POST_INQUIRY_STATUS_UPDATE = "enquiry/update-status/";
export const API_ENDPOINT_GET_INQUIRY_BY_ID = "admin/enquiry/";
//locationcontent
export const API_ENDPOINT_GET_LOCATION_CONTENT = "content/location/list";
export const API_ENDPOINT_GET_DETAILS_LOCATION_CONTENT =
  "content/location/detail";
export const API_ENDPOINT_CREATE_LOCATION_CONTENT = "content/location/create";
export const API_ENDPOINT_UPDATE_LOCATION_CONTENT = "content/location/update";

//technologycontent
export const API_ENDPOINT_GET_TECHNOLOGY_CONTENT = "content/technology/list";
export const API_ENDPOINT_GET_DETAILS_TECHNOLOGY_CONTENT =
  "content/technology/detail";
export const API_ENDPOINT_CREATE_TECHNOLOGY_CONTENT =
  "content/technology/create";
export const API_ENDPOINT_UPDATE_TECHNOLOGY_CONTENT =
  "content/technology/update";

//notification
export const API_ENDPOINT_NOTIFICATION = "users/notify";

//onboard
export const API_ENDPOINT_SELLER_REGISTER = "/admin/seller-register";
export const API_ENDPOINT_BUYER_REGISTER = "/admin/buyer-register";
export const API_ENDPOINT_SELLER_DETAILS = "users/seller/details";
export const API_ENDPOINT_SELLER_UPDATE = "/admin/update-seller";

//CreateCompany
export const API_ENDPOINT_CREATE_COMPANY = "admin/update-company";

//Job Desc
export const POST_JOB_DESC_UPLOAD = `job-description/upload`;

//Listings
export const LOCATIONS_LIST = `/location/list`;
export const SKILLS_LIST = `/technology/list`;
export const STATE_LIST = `location/state/list`;
export const CITIES_LIST = `/location/city/list`;

// resaume builder
export const RESUME_LIST = `/resource/resume-builder/list`;
export const RESUME_CREATE = `admin/resource/resume-builder/create`;

// evaluation form
export const EVALUATION_CREATE = `/resource/evaluation/create`;
export const EVALUATION_UPDATE = `/resource/evaluation/update`;
export const EVALUATION_LIST = `/resource/evaluation/list`;
export const API_ENDPOINT_GET_EVALUATION_DETAILS = "/resource/evaluation/get";

// interview
export const INTERVIEW_CREATE = `admin/schedule-interview`;
export const RESHEDULE_INTERVIEW_CREATE = `/admin/schedule-interview/update`;
export const GET_INTERVIEW_DETAILS = "/get/admin/schedule-interview";
export const GET_INTERVIEW_LIST = "admin/schedule-interview/all-list";

// MSA
export const GET_MSA_LIST_ENDPOINT = "/msa";
export const ZX_MSA_CREATE_ENDPOINT = "/msa/zx";
